<template>
  <v-container fluid>
    <v-snackbar :value="isError" color="error">
      {{ isError ? getError.errorMessage : '' }}
      <v-btn color="white" text @click="setError({ isError: false, error: null})">Cerrar</v-btn>
    </v-snackbar>
    <v-snackbar :value="infoSnackbar" color="success">
      {{ infoSnackbar ? infoSnackbarMessage : '' }}
      <v-btn color="white" text @click="infoSnackbar = false">Cerrar</v-btn>
    </v-snackbar>
    <v-dialog v-model="asignarVentasDialog" width="550px">
      <v-card>
        <v-card-title class="headline">Agregar Encargado</v-card-title>
        <v-card-text class="pa-0">
          <v-container fluid class="pb-0">
            <div v-if="isLoading">
              <v-skeleton-loader class="mx-auto" type="sentences"></v-skeleton-loader>
            </div>
            <div v-else>
              <v-form>
                <div class="d-flex">
                  <v-select
                    outlined
                    v-model="nuevoEncargadoSeleccionado"
                    label="Nuevo Encargado"
                    required
                    :items="listaEncargadosRoles.encargados"
                    item-text="nombreCompleto"
                    item-value="idTrabajador"
                    class="mx-2"
                    :loading="isLoading"
                    style="width:60%"
                  ></v-select>
                  <v-select
                    outlined
                    v-model="nuevoRolSeleccionado"
                    item-text="cargo"
                    item-value="idCargo"
                    label="Rol"
                    required
                    :items="listaEncargadosRoles.roles"
                    class="mx-2"
                    style="width:40%"
                  ></v-select>
                </div>
              </v-form>
            </div>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-btn color text @click="asignarVentasDialog = false">Cancelar</v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="teal"
            text
            :loading="isLoading"
            :disabled="isLoading"
            @click="crearEncargadoVenta"
          >Agregar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="openDialogEliminar" persistent max-width="290">
      <v-card>
        <v-card-title class="headline">¿Estás seguro?</v-card-title>
        <v-card-text>Esta acción no se puede deshacer</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="openDialogEliminar = false">Cancelar</v-btn>
          <v-btn
            color="green darken-1"
            text
            :loading="isLoading"
            @click.prevent="btnEliminarEncargado"
          >Confirmar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-row no-gutters>
      <v-col>
        <v-data-table
          v-model="listaAsignacionMultiple"
          fixed-header
          :show-select="asignacionMultipleCobranza"
          :headers="headers"
          :items="ventasFiltered"
          :loading="isLoading"
          loading-text="Cargando... Espera un Momento"
          no-data-text="No hay datos para mostrar"
          :search="buscarTabla"
          item-key="idVenta"
          single-expand
          :show-expand="!asignacionMultipleCobranza"
          class="elevation-1"
          @item-expanded="whenExpanded"
        >
          <template v-slot:top>
            <v-toolbar flat color="white">
              <div>
                <v-toolbar-title>VENTAS</v-toolbar-title>
              </div>
              <v-divider class="mx-4" inset vertical></v-divider>
              <v-toolbar-title class="subtitle-1">Crédito</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-btn icon color="success" class="mx-2" @click="actualizarTabla">
                <v-icon>mdi-reload</v-icon>
              </v-btn>
            </v-toolbar>
            <v-layout class="py-3 d-flex flex-column">
              <v-row>
                <v-col cols="12" sm="12" md="5">
                  <v-text-field
                    outlined
                    v-model="buscarTabla"
                    dense
                    prepend-inner-icon="mdi-magnify"
                    label="Buscar en la tabla..."
                    class="mx-4 flex-shrink-0 flex-grow-1"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="12" md="3">
                  <v-dialog
                    ref="dialogFechaTabla"
                    v-model="modalFechaTabla"
                    :return-value.sync="fechaTabla"
                    width="290px"
                    class="flex-shrink-0 flex-grow-0"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        v-model="computedFechaFormateada"
                        label="Fecha de Selección"
                        prepend-inner-icon="mdi-calendar"
                        outlined
                        dense
                        readonly
                        v-on="on"
                        class="mx-4"
                      ></v-text-field>
                    </template>
                    <v-date-picker v-model="fechaTabla" scrollable locale="es">
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="modalFechaTabla = false">Cancelar</v-btn>
                      <v-btn text color="primary" @click="actualizarFecha()">OK</v-btn>
                    </v-date-picker>
                  </v-dialog>
                </v-col>
                <v-col cols="12" sm="12" md="2">
                  <v-switch
                    v-model="selectListaVentaCobranza"
                    color="green"
                    :label="selectListaVentaCobranza ? 'Listar Cobranzas' : 'Listar Ventas'"
                    class="mx-4"
                  ></v-switch>
                </v-col>
                <v-col cols="12" sm="12" md="2">
                  <v-switch
                    v-model="selectCobranzasSinCobrar"
                    color="red"
                    :label="selectCobranzasSinCobrar ? 'Deudas Pendientes' : 'Deudas Cobradas'"
                    class="mx-4"
                  ></v-switch>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="12" class="d-flex justify-end">
                  <v-btn
                    color="base"
                    outlined
                    class="mx-4"
                    v-if="!asignacionMultipleCobranza"
                    @click="asignacionMultipleCobranza = true"
                  >
                    Asignar Múltiples Ventas
                    <v-icon right>mdi-account-multiple-plus</v-icon>
                  </v-btn>
                </v-col>
                <v-col cols="12" sm="12" class="d-flex justify-end">
                  <v-btn
                    outlined
                    :dark="!!ventas.length"
                    :disabled="ventas.ventas || ventas.cobranzas ? !ventas.ventas.length || !ventas.cobranzas.length : false"
                    class="mx-4"
                    color="green"
                    v-if="!asignacionMultipleCobranza"
                    @click="exportToExcel"
                  >
                    <v-icon left>mdi-file-excel</v-icon>Exportar
                  </v-btn>
                </v-col>
                <v-col cols="12" sm="12" class="d-flex justify-end">
                  <v-btn
                    color="info"
                    outlined
                    class="mx-4"
                    v-if="asignacionMultipleCobranza"
                    @click="asignarEncargados"
                    :disabled="!listaAsignacionMultiple.length"
                  >
                    Seleccionar Encargado
                    <v-icon right>mdi-clipboard-account</v-icon>
                  </v-btn>
                  <v-btn
                    color="red"
                    outlined
                    class="mx-4"
                    v-if="asignacionMultipleCobranza"
                    @click="reiniciarAsignarMultiple"
                  >
                    Cancelar
                    <v-icon right>mdi-cancel</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-layout>
          </template>
          <template v-slot:item.costoVentaTotal="{ item }">
            S/ {{
            item.costoVentaTotal.toFixed(2)
            }}
          </template>
          <template v-slot:item.saldoActual="{ item }">
            <v-chip :color="getEstado(item.saldoActual)" dark>
              S/ {{
              item.saldoActual.toFixed(2)
              }}
            </v-chip>
          </template>
          <template v-slot:item.cantidadPrestados="{ item }">
            <v-chip :color="item.cantidadPrestados ? 'orange': ''" dark>
              {{
              item.cantidadPrestados
              }}
            </v-chip>
          </template>
          <template v-slot:item.fechaVenta="{ item }">{{ formatDateTable(item.fechaVenta) }}</template>
          <template v-slot:item.asignados="{ item }">
            <v-icon
              :color="item.asignados ? 'green' : 'red'"
            >mdi-{{item.asignados ? 'check-bold' : 'close-thick'}}</v-icon>
          </template>
          <template v-slot:expanded-item="{ headers, item }" style="width: 95%">
            <td :colspan="headers.length" v-if="expandedLoading">
              <v-skeleton-loader ref="expSkeleton" type="article" class="mx-auto"></v-skeleton-loader>
            </td>

            <td :colspan="headers.length" v-else>
              <div v-if="Object.keys(venta).length">
                <v-container fluid>
                  <v-layout>
                    <v-row justify="center">
                      <v-col cols="12" sm="12" md="12" class="pb-0">
                        <div class="d-flex justify-space-around">
                          <p>
                            Productos Vendidos:
                            <span>{{item.cantidadVendidos}}</span>
                          </p>
                          <p>
                            Productos Prestados:
                            <span>{{item.cantidadPrestados}}</span>
                          </p>
                          <p>
                            Deuda Actual:
                            <span>S/ {{item.saldoActual}}</span>
                          </p>
                        </div>
                      </v-col>
                      <v-col cols="12" sm="12" md="6" class="pt-0">
                        <v-subheader>Lista de Productos</v-subheader>
                        <v-data-table
                          dense
                          :items="venta.productos"
                          :headers="headersListaProductos"
                          item-key="idVenta"
                          hide-default-footer
                        >
                          <template v-slot:[item.costoUnitario]="{ value }">S/ {{value}}</template>
                          <template
                            v-slot:[item.costoTotalProducto]="{ value }"
                          >S/ {{value.toFixed(2)}}</template>
                        </v-data-table>
                        <v-subheader>Lista de Cobranzas</v-subheader>
                        <v-simple-table dense>
                          <template v-slot:default>
                            <thead>
                              <tr>
                                <th class="text-left">ID</th>
                                <th class="text-left">Monto Cobrado</th>
                                <th class="text-left">Fecha de Cobro</th>
                              </tr>
                            </thead>
                            <tbody>
                              <template v-if="venta.cobranzas.length">
                                <tr
                                  v-for="(itemCobranza, index) in venta.cobranzas"
                                  :key="`${venta.detalleVenta.idVenta}-${itemCobranza.idTrabajador}-${index}`"
                                >
                                  <td>{{ itemCobranza.idCobranza }}</td>
                                  <td>S/ {{ itemCobranza.montoCobrado.toFixed(2) }}</td>
                                  <td>{{ formatDateTable(itemCobranza.fechaCobranza) }}</td>
                                </tr>
                              </template>
                              <template v-else>
                                <tr class="text-center">
                                  <td colspan="3" class="text--disabled body-2">No hay datos</td>
                                </tr>
                              </template>
                            </tbody>
                          </template>
                        </v-simple-table>
                      </v-col>
                      <v-col cols="12" sm="12" md="6" class="pt-0">
                        <v-subheader>Lista de Encargados</v-subheader>
                        <v-simple-table dense>
                          <template v-slot:default>
                            <thead>
                              <tr>
                                <th class="text-left">Nombre</th>
                                <th class="text-left">Cargo</th>
                                <th class="text-center">Acciones</th>
                              </tr>
                            </thead>
                            <tbody>
                              <template v-if="venta.encargados.length">
                                <tr
                                  v-for="(itemEncargado, index) in venta.encargados"
                                  :key="`${venta.detalleVenta.idVenta}-${itemEncargado.idTrabajador}-${index}`"
                                >
                                  <td>{{ itemEncargado.nombre }} {{ itemEncargado.apellidos }}</td>
                                  <td>{{ itemEncargado.cargo }}</td>
                                  <td class="text-center">
                                    <v-btn color="blue" text icon>
                                      <v-icon>mdi-content-save-edit</v-icon>
                                    </v-btn>
                                    <v-btn
                                      color="red"
                                      text
                                      icon
                                      @click="handleDeleteBtn(itemEncargado)"
                                    >
                                      <v-icon>mdi-delete-circle</v-icon>
                                    </v-btn>
                                  </td>
                                </tr>
                              </template>
                              <template v-else>
                                <tr class="text-center">
                                  <td colspan="3" class="text--disabled body-2">No hay datos</td>
                                </tr>
                              </template>
                            </tbody>
                          </template>
                        </v-simple-table>

                        <v-subheader>Lista de Devoluciones</v-subheader>
                        <v-simple-table dense>
                          <template v-slot:default>
                            <thead>
                              <tr>
                                <th class="text-left">Producto</th>
                                <th class="text-left">Cantidad</th>
                                <th class="text-left">Fecha de Devolución</th>
                              </tr>
                            </thead>
                            <tbody>
                              <template v-if="venta.recojos.length">
                                <tr v-for="(itemRecojo, index) in venta.recojos" :key="index">
                                  <td>{{ itemRecojo.nombreProducto }}</td>
                                  <td>{{ itemRecojo.cantidad }}</td>
                                  <td>{{ formatDateTable(itemRecojo.fechaRecuperacion) }}</td>
                                </tr>
                              </template>
                              <template v-else>
                                <tr class="text-center">
                                  <td colspan="3" class="text--disabled body-2">No hay datos</td>
                                </tr>
                              </template>
                            </tbody>
                          </template>
                        </v-simple-table>
                      </v-col>
                      <v-col cols="12" sm="12" class="d-flex justify-end">
                        <v-btn
                          color="green"
                          text
                          large
                          outlined
                          @click="asignarEncargados"
                          v-if="!asignacionMultipleCobranza"
                        >
                          <v-icon left>mdi-clipboard-account</v-icon>Asignar
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-layout>
                </v-container>
              </div>
            </td>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import XLSX from 'xlsx'

export default {
  data: vm => ({
    verificado: true,
    asignarVentasDialog: false,
    editarVentaDialog: false,
    selectCobranzasSinCobrar: true,
    headers: [
      {
        text: 'ID de Venta',
        value: 'idVenta',
        align: 'center',
        width: '5%',
        filterable: false
      },
      {
        text: 'Cliente',
        value: 'nombreCliente',
        align: 'center',
        filterable: true
      },
      {
        text: 'Cantidad de Items Vendidos',
        value: 'cantidadVendidos',
        align: 'center',
        width: '5%',
        filterable: false
      },
      {
        text: 'Cantidad de Items Prestados',
        value: 'cantidadPrestados',
        align: 'center',
        width: '5%',
        filterable: false
      },
      {
        text: 'Monto Total (S/)',
        value: 'costoVentaTotal',
        align: 'center',
        filterable: false
      },
      {
        text: 'Fecha y Hora de Venta',
        value: 'fechaVenta',
        align: 'center',
        filterable: false
      },
      {
        text: 'Saldo Deudor (S/)',
        value: 'saldoActual',
        align: 'center',
        filterable: false
      },
      {
        text: 'Vendedor',
        value: 'vendedor',
        align: 'center',
        filterable: true
      },
      {
        text: 'Cobrador Asignado',
        value: 'asignados',
        align: 'center',
        filterable: false
      },
      { text: '', value: 'data-table-expand' }
    ],
    headersListaProductos: [
      { text: 'Producto', value: 'nombreProducto', align: 'center' },
      { text: 'Costo/u', value: 'costoUnitario', align: 'center' },
      { text: 'Cantidad', value: 'cantidad', align: 'center' },
      { text: 'Costo Total', value: 'costoTotalProducto', align: 'center' }
    ],
    ventas: [],
    fechaTabla: new Date(new Date().setHours(-5)).toISOString().substr(0, 10),
    modalFechaTabla: false,
    buscarTabla: '',
    infoSnackbar: false,
    infoSnackbarMessage: '',
    expandedLoading: false,
    idVentaSeleccionada: '',
    precioRules: [
      v => !isNaN(v) || 'Solo se permite números',
      v => v > 0 || 'Número mayor a 0!'
    ],
    editedItem: {
      idVenta: 0,

      nombreCliente: '',
      cantidadVendidos: 0,
      cantidadPrestados: 0,
      costoVentaTotal: 0,
      fechaVenta: '',
      saldoActual: 0,
      asignados: 0
    },
    defaultItem: {
      idVenta: 0,
      nombreCliente: '',
      cantidadVendidos: 0,
      cantidadPrestados: 0,
      costoVentaTotal: 0,
      fechaVenta: '',
      saldoActual: 0,
      asignados: 0
    },
    editVentaItem: {
      idVenta: 0,
      idCliente: 0,
      costoVentaTotal: 0,
      productos: []
    },
    venta: {},
    listaEncargados: {},
    nuevoRolSeleccionado: '',
    nuevoEncargadoSeleccionado: '',
    listaEncargadosRoles: { encargados: [], roles: [] },
    listaProductos: [],
    openDialogEliminar: false,
    itemEncargadoSeleccionado: {},
    listaAsignacionMultiple: [],
    asignacionMultipleCobranza: false,
    selectListaVentaCobranza: false
  }),
  methods: {
    getEstado(venta) {
      if (venta >= 300) return 'red'
      else if (venta >= 150) return 'yellow'
      else if (venta == 0) return ''
      else return 'red'
    },

    getForm(i) {
      this.$emit('change', i)
    },

    formatDate(date) {
      if (!date) return null
      const [year, month, day] = date.split('-')
      return `${day}/${month}/${year}`
    },

    formatDateTable(date) {
      let parsedDate = new Date(parseInt(date))
      let options = {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit'
      }
      return `${parsedDate.toLocaleDateString(
        'es-PE',
        options
      )} ${parsedDate.toLocaleTimeString()}`
    },

    formatFechasCobranzas(stringFechas) {
      let arrayFechas = stringFechas.split('|')
      const vm = this
      let arrayFechasFormateadas = arrayFechas.map(function(fecha) {
        return vm.formatDateTable(fecha)
      })
      return arrayFechasFormateadas.join('|')
    },

    cleanSource(source) {
      return JSON.parse(JSON.stringify(source))
    },

    async actualizarTabla() {
      let horaCeroInicio = new Date(this.fechaTabla).setHours(24)
      let horaCeroFin = new Date(this.fechaTabla).setHours(48)
      //this.setError({ isError: false, error: "" });
      let fechas = { horaCeroInicio, horaCeroFin }
      this.ventas = await this.fetchVentasPorCobrarAdmin(fechas)
      //console.log(this.ventas)
    },

    actualizarFecha() {
      this.$refs.dialogFechaTabla.save(this.fechaTabla)
      this.actualizarTabla()
    },

    async whenExpanded(row) {
      if (row.value) {
        this.expandedLoading = true
        this.idVentaSeleccionada = row.item.idVenta
        this.venta = await this.fetchDetalleVenta(this.idVentaSeleccionada)
        this.expandedLoading = false
      }
    },

    eliminarVenta(item) {
      console.log(item)
    },

    async asignarEncargados() {
      this.asignarVentasDialog = true
      let response = await this.fetchEncargadosRoles()
      for (let i = 0; i < response.encargados.length; i++) {
        response.encargados[i].nombreCompleto =
          response.encargados[i].nombre + ' ' + response.encargados[i].apellidos
      }
      this.listaEncargadosRoles = response
    },

    async editarVenta() {
      this.editarVentaDialog = true
      this.listaEncargadosRoles = await this.fetchEncargadosRoles()
    },

    async crearEncargadoVenta() {
      let listaAsignar = []

      if (this.asignacionMultipleCobranza) {
        for (let i = 0; i < this.listaAsignacionMultiple.length; i++) {
          listaAsignar.push(this.listaAsignacionMultiple[i].idVenta)
        }
      } else {
        listaAsignar.push(this.idVentaSeleccionada)
      }

      let payload = {
        ventas: listaAsignar,
        idTrabajador: this.nuevoEncargadoSeleccionado,
        idCargo: this.nuevoRolSeleccionado
      }
      await this.crearEncargado(payload)
      if (this.idVentaSeleccionada != '') {
        this.nuevoRolSeleccionado = ''
        this.nuevoEncargadoSeleccionado = ''
        this.expandedLoading = true
        this.venta = await this.fetchDetalleVenta(this.idVentaSeleccionada)
        this.expandedLoading = false
      }
      if (this.asignacionMultipleCobranza) this.reiniciarAsignarMultiple()
      await this.actualizarTabla()
      this.asignarVentasDialog = false
    },

    handleDeleteBtn(row) {
      this.openDialogEliminar = true
      this.itemEncargadoSeleccionado = row
    },

    async btnEliminarEncargado() {
      let payload = {
        idVenta: this.idVentaSeleccionada,
        idTrabajador: this.itemEncargadoSeleccionado.idTrabajador,
        idCargo: this.itemEncargadoSeleccionado.idCargo
      }
      await this.eliminarEncargado(payload)
      await this.actualizarTabla()
      this.venta = await this.fetchDetalleVenta(this.idVentaSeleccionada)
      this.openDialogEliminar = false
    },

    reiniciarAsignarMultiple() {
      this.asignacionMultipleCobranza = false
      this.listaAsignacionMultiple = []
    },

    ...mapActions('user', [
      'fetchVentasPorCobrarAdmin',
      'fetchEncargadosRoles',
      'crearEncargado',
      'eliminarEncargado'
    ]),

    exportToExcel() {
      let resource = this.cleanSource(this.ventasFiltered)

      for (let i = 0; i < resource.length; i++) {
        resource[i].fechaVenta = this.formatDateTable(resource[i].fechaVenta)
        // resource[i].fechasCobranzas = this.formatFechasCobranzas(
        //   resource[i].fechasCobranzas
        // )
      }

      let dataExported = XLSX.utils.json_to_sheet(resource)
      dataExported['A1'].v = 'ID de Venta'
      dataExported['B1'].v = 'Nombre del Cliente'
      dataExported['C1'].v = 'Monto Venta Total'
      dataExported['D1'].v = 'Fecha de Venta'
      dataExported['E1'].v = 'Saldo Deudor'
      dataExported['F1'].v = '# de Items Vendidos'
      dataExported['G1'].v = '# de Items Prestados'
      dataExported['H1'].v = '# de Items Prestados Actualmente'
      dataExported['I1'].v = '# de Cobradores Asignados'
      dataExported['J1'].v = 'Vendedor'
      dataExported['K1'].v = 'Cobrador'
      //dataExported['L1'].v = 'Fechas de Cobranzas'

      const workbook = XLSX.utils.book_new()
      const filename = `${new Date().getTime()}-cobranzasAdmin`
      XLSX.utils.book_append_sheet(workbook, dataExported, filename)
      XLSX.writeFile(workbook, `${filename}.xlsx`)
    },

    ...mapActions('venta', ['fetchDetalleVenta']),

    ...mapMutations('user', ['setError', 'loadingRequest'])
  },
  computed: {
    computedFechaFormateada() {
      return this.formatDate(this.fechaTabla)
    },

    getFechaTabla() {
      const offsetDate = new Date(this.fechaTabla).setHours(
        new Date(this.fechaTabla).getHours() - 5
      )
      return new Date(offsetDate).toISOString().substr(0, 10)
    },

    getFechaFormateada() {
      return new Date(this.fechaTabla).toISOString().substr(0, 10)
    },

    getErrorMessage() {
      switch (this.getError.errorMessage) {
        case 'Network Error':
          return 'Error al intentar conectarse al servidor, contacte al administrador'
        default:
          return this.getError.errorMessage
      }
    },

    ventasFiltered() {
      if (this.selectCobranzasSinCobrar) {
        if (this.ventas.ventas || this.ventas.cobranzas) {
          if (this.selectListaVentaCobranza) {
            return this.ventas.cobranzas.filter(
              item => item.saldoActual > 0 || item.cantidadPrestadosActual > 0
            )
          } else {
            return this.ventas.ventas.filter(
              item => item.saldoActual > 0 || item.cantidadPrestadosActual > 0
            )
          }
        } else {
          return []
        }
      } else {
        if (this.ventas.ventas || this.ventas.cobranzas) {
          if (this.selectListaVentaCobranza) {
            return this.ventas.cobranzas.filter(
              item => item.saldoActual == 0 && item.cantidadPrestadosActual == 0
            )
          } else {
            return this.ventas.ventas.filter(
              item => item.saldoActual == 0 && item.cantidadPrestadosActual == 0
            )
          }
        } else {
          return []
        }
      }
    },

    ...mapGetters('user', [
      'isError',
      'getError',
      'isLoading',
      'getListaProductosFiltrada'
    ])
  },

  mounted: async function() {
    await this.actualizarTabla()
  }
}
</script>
